import React from 'react'
import AdminLayout from './AdminLayout/AdminLayout'

const Dashboard = () => {
    return (
        <>
        <AdminLayout>

    
         
                <div className="dashboard container">
                    <h1>Dashboard</h1>

                </div>
                </AdminLayout>
        </>
    )
}

export default Dashboard